import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import PropTypes from 'prop-types';

// type LoaderProps = {
//   type?:
//     | 'TailSpin'
//     | 'Audio'
//     | 'BallTriangle'
//     | 'Bars'
//     | 'Circles'
//     | 'Grid'
//     | 'Hearts'
//     | 'Oval'
//     | 'Puff'
//     | 'Rings'
//     | 'ThreeDots'
//     | 'Watch'
//     | 'RevolvingDot'
//     | 'Triangle'
//     | 'Plane'
//     | 'MutatingDots'
//     | 'CradleLoader';
// };

const LoaderComponent = ({ type = 'TailSpin' }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Loader type={type} color="#009345" height={50} width={50} />
  </div>
);

LoaderComponent.defaultProps = {
  type: 'TailSpin',
};
LoaderComponent.propTypes = {
  type: PropTypes.string,
};

export default LoaderComponent;
