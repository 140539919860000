import React from 'react';
import { useHistory } from 'react-router-dom';

import StatusPage from '../../components/StatusPage/StatusPage';

const Declined = () => {
  const history = useHistory();

  const token = history.location.state?.token;

  return (
    <StatusPage
      responseMessage="Помилка відправки"
      text1row="Не вдалося відправити чайові:"
      text2row="спробуйте ще:\"
      btnText="ПОВЕРНУТИСЯ"
      token={token}
    />
  );
};

export default Declined;
