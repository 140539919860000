import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ContinueButton.style.scss';
// import { ROUTES } from '../../core/_consts/routes.ts';

const ContinueButton = ({
  amount,
  handleAmountClick,
  setLoading,
  buttonText,
  isDisabled,
  token,
  validate,
}) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    // console.info(validate, amount);
    if (amount !== undefined) {
      setLoading();
      handleAmountClick(amount);
    } else {
      history.push(`wog?token=${token}`);
    }
    if (validate) {
      validate(amount);
    }
  }, [amount, validate, setLoading, handleAmountClick, history, token]);

  return (
    <button
      id="nav"
      type="button"
      disabled={isDisabled}
      className="button button_submit"
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};
ContinueButton.defaultProps = {
  amount: undefined,
  isDisabled: false,
  handleAmountClick: undefined,
  token: undefined,
  setLoading: undefined,
  validate: undefined,
};
ContinueButton.propTypes = {
  amount: PropTypes.number,
  setLoading: PropTypes.func,
  handleAmountClick: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  token: PropTypes.string,
  validate: PropTypes.func,
};
export default ContinueButton;
