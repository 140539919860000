import React from 'react';
import './MainPageLayout.styles.scss';
import PropTypes from 'prop-types';
import Header from '../Header/Header';

const MainPageLayout = ({ children }) => (
  <div className="main">
    <Header />
    <div className="main__children">{children}</div>
  </div>
);

MainPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainPageLayout;
