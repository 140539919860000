import React from 'react';
import PropTypes from 'prop-types';
import ContinueButton from '../ContinueButton';
// import './StatusPage.styles.scss';
import styles from './StatusPage.module.scss';

const StatusPage = ({
  responseMessage,
  text1row,
  text2row,
  btnText,
  token,
}) => (
  <div className={styles.content}>
    <div className={styles.responseMessage}>{responseMessage}</div>
    <div className={styles.textForUser}>
      <div className={styles.text1row}>{text1row}</div>
      <div className={styles.text2row}>{text2row}</div>
    </div>
    {btnText && <ContinueButton buttonText={btnText} token={token} />}
  </div>
);

StatusPage.defaultProps = {
  responseMessage: null,
  text1row: null,
  text2row: null,
  btnText: null,
  token: null,
};

StatusPage.propTypes = {
  responseMessage: PropTypes.string,
  text1row: PropTypes.string,
  text2row: PropTypes.string,
  btnText: PropTypes.string,
  token: PropTypes.string,
};

export default StatusPage;
