import React from 'react';
import StatusPage from '../../components/StatusPage/StatusPage';

const Canceled = () => (
  <StatusPage
    responseMessage="Невірні параметри"
    text1row="Невірні вхідні параметри:"
    text2row="спробуйте знову:\"
  />
);

export default Canceled;
