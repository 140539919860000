/* eslint-disable import/prefer-default-export */
const prod = {
  url: {
    API_URL: 'https://api.users.actinia.com.ua/v1',
  },
};

const sandbox = {
  url: {
    // API_URL: 'http://localhost:8082/v1',
    API_URL: 'https://api.users.sandbox.actinia.tech/v1',
  },
};

const dev = {
  url: {
    // API_URL: 'http://localhost:8082/v1',
    API_URL: 'http://localhost:8080',
  },
};

const config = prod;

switch (process.env.NODE_ENV) {
  case 'development':
    config.url.API_URL = dev.url.API_URL;
    break;
  case 'sandbox':
    config.url.API_URL = sandbox.url.API_URL;
    break;
  case 'production':
    config.url.API_URL = prod.url.API_URL;
    break;
  default:
    config.url.API_URL = prod.url.API_URL;
}

if (process.env.REACT_APP_CUSTOM_NODE_ENV) {
  config.url.API_URL = sandbox.url.API_URL;
}

console.info('app Config', config, process.env.NODE_ENV, process.env.REACT_APP_CUSTOM_NODE_ENV);

export {
  config,
};
// export const config = process.env.NODE_ENV === 'development' ? dev : prod;
