/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './Header.scss';

const Header = () => (
  <div className="header">
    {/* <div */}
    {/*  onKeyPress={() => { */}
    {/*    console.log(); */}
    {/*  }} */}
    {/*  tabIndex={0} */}
    {/*  role="button" */}
    {/*  className="btn_close" */}
    {/*  onClick={() => { */}
    {/*    window.location.href = 'https://wog.ua/'; */}
    {/*  }} */}
    {/* >  */}
    {/*  <span className="material-icons">close</span> */}
    {/* </div> */}
    {/* <div className="content item_whiteback"> */}
    {/* <div className="text_item text_item_greenback"> */}
    <div className="header__title">WOG tips</div>
    <div className="header__subtitle">оплата чайових карткою</div>
    {/* </div> */}
    {/* </div> */}
  </div>
);

export default Header;
