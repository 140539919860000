import React from 'react';
import './App.scss';
import { Route, Switch, useHistory } from 'react-router-dom';
import FormInput from './components/FormInput/FormInput';
import { ROUTES } from './core/_consts/routes.ts';
import Accepted from './pages/Success/Success';
import Declined from './pages/Declined/Declined';
import Canceled from './pages/Cancel/Cancel';

function App() {
  const history = useHistory();
  const locationQueryArray = window.location.search.split('=');
  // console.info('location!!', history, locationQueryArray);

  let status = null;
  let token = locationQueryArray[1];

  if (locationQueryArray.length > 2) {
    // eslint-disable-next-line prefer-destructuring
    status = locationQueryArray[2];

    // console.info('Status!!!', status);

    if (status === 'approve') {
      // eslint-disable-next-line prefer-destructuring
      token = locationQueryArray[1].split(';')[0];
      // console.info('Status approve!!!', status, token);
      history.push(ROUTES.ACCEPTED, {
        token,
      });
    }
    if (status === 'decline') {
      // eslint-disable-next-line prefer-destructuring
      token = locationQueryArray[1].split(';')[0];
      history.push(ROUTES.DECLINED, {
        token,
      });
    }
    // history.push(ROUTES.HOME);
  }

  // eslint-disable-next-line no-mixed-operators
  if (!token || !status && token?.length > 12) {
    history.push(ROUTES.CANCELED);
  }

  // console.info('App!!', token);

  return (
    <Switch>
      <Route exact path={ROUTES.HOME} component={FormInput} />
      <Route path={ROUTES.ACCEPTED} component={Accepted} />
      <Route path={ROUTES.DECLINED} component={Declined} />
      <Route path={ROUTES.CANCELED} component={Canceled} />
    </Switch>
  );
}

export default App;
